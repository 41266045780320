import React from "react";
import Pagetitle from "../elements/Pagetitle";

const brandlogoData = [
  {
    id: 1,
    image: "images/javascript-1.svg",
    title: "Javascript",
  },
  {
    id: 8,
    image: "images/html-1.svg",
    title: "Html",
  },
  {
    id: 9,
    image: "images/css-3.svg",
    title: "CSS",
  },
  {
    id: 4,
    image: "images/nodejs-1.svg",
    title: "NodeJS",
  },
  {
    id: 2,
    image: "images/react-2.svg",
    title: "ReactJS",
  },
  {
    id: 3,
    image: "images/react-native-1.svg",
    title: "React Native",
  },
  {
    id: 6,
    image: "images/graphql-logo-2.svg",
    title: "Graphql",
  },

  {
    id: 5,
    image: "images/prisma-2.svg",
    title: "Prisma",
  },

  {
    id: 7,
    image: "images/apollo-graphql-1.svg",
    title: "Apollo Graph",
  },

  {
    id: 10,
    image: "images/unity-technologies-logo.svg",
    title: "Unity",
  },
  {
    id: 11,
    image: "images/c--4.svg",
    title: "C#",
  },
  {
    id: 12,
    image: "images/mysql-6.svg",
    title: "MySQL",
  },
  {
    id: 13,
    image: "images/aws-logo.svg",
    title: "AWS Services",
  },
  {
    id: 14,
    image: "images/git-icon.svg",
    title: "Git",
  },
  {
    id: 15,
    image: "images/serveless.svg",
    title: "Serverless",
  },
  {
    id: 16,
    image: "images/docker.svg",
    title: "Docker",
  },
  {
    id: 17,
    image: "images/devops-2.svg",
    title: "DevOps",
  },
  {
    id: 18,
    image: "images/wordpress-blue.svg",
    title: "Wordpress",
  },
];

function Brandlogos() {
  return (
    <div id="brandlogos">
      <div className="container" style={{ marginTop: "100px" }}>
      <Pagetitle title="Skills" />
        <div className="row">
          {brandlogoData.map((brandlogo) => (
            <div className="col-md-2 col-6" key={brandlogo.id}>
              <div className="client-item">
                <div className="inner">
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={brandlogo.image}
                    alt="client-name"
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#454360",
                      fontFamily: "Rubik",
                      fontWeight: "500"
                    }}
                  >
                    {brandlogo.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Brandlogos;
