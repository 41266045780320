import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Franky",
  avatarImage: "/images/logo-franky.png",
  content:
    "I am Frankelly Veras, Full Stack Web and Mobile Developer from Dominican Republic 🇩🇴 🔥. I’m an energetic and enthusiastic software engineer with 10+ years experience  developing robust code for high-volume businesses, a results-driven, customer-focused, analytical software engineer who can think “out of the box”. Strong in design and integration problem-solving skills.",
};

const progressData = [
  {
    id: 1,
    title: "Problem-Solving / Critical Thinking",
    percantage: 99,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Collaboration / Teamwork",
    percantage: 99,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Adaptability",
    percantage: 99,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "Knowledge of the full stack",
    percantage: 99,
    progressColor: "#2ecc71",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 20,
    icon: "images/analytics.png",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 123,
    icon: "images/coffee-cup.png",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 14,
    icon: "images/target.png",
  },
  // {
  //   id: 4,
  //   title: "Nominees winner",
  //   count: 35,
  //   icon: "icon-badge",
  // },
];

function About() {
  return (
    <section
      id="about"
      style={{
        backgroundImage: `url(/images/hero-bg-white.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: "50px",
      }}
    >
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a
                      href="/frankelly_veras_cv.pdf"
                      download={true}
                      className="btn btn-default"
                    >
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-4 col-sm-12">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
