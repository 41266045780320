import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/web-development.png",
    title: "Web Development",
    content:
      "With over 10 years of experience, I can help you with every step of the website/web apps development process, from planning to execution.With a detailed understanding of databases, back-end logic, APIs, architecture, and servers, I ensure that your website looks good and run smoothly.",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/profile.png",
    title: "Mobile Development",
    content:
      "I can help you create top-notch mobile applications that run on multiple platforms, providing your users with an app that fits their OS, either Android or IOS, I can ensure app success by delivering striking UI, secure app code, and resilient back ends by using the latest and best technologies.",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/coding.png",
    title: "Game Development",
    content:
      "Got a video game project?, I can help you bring your idea to life by writing customizable scripts that are easy to adjust to suit the needs of different projects, build key game features, from player inventories to friendly and enemy artificial intelligence, while using one of the best real time game engines Unity.",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
