import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor’s Degree In Software Engineering",
    years: "2015 - 2018",
    content:
      "UNAPEC",
  },
  {
    id: 2,
    title: "Associate Software Engineering",
    years: "2012 - 2015",
    content:
      "Las Americas Institute Of Technology",
  },
  {
    id: 3,
    title: "High School",
    years: "2007 - 2012",
    content:
      "Academia La Trinitaria",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Lead Full Stack Developer",
    years: "2018 - Present",
    content:
      "Stagewood Inc.",
  },
  {
    id: 2,
    title: "Full Stack Web Developer",
    years: "2016 - 2017",
    content:
      "IT Group",
  },
  {
    id: 3,
    title: "Full Stack .Net Developer",
    years: "2015 - 2016",
    content:
      "Baroli Technologies",
  },
  {
    id: 4,
    title: "Full Stack .Net Developer",
    years: "2014 - 2015",
    content:
      "Holding Electric Services",
  },
];

function Experiences() {
  return (
    <section id="experience"
    >
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
